body {
    background-color: #F3F9E6;
}

.header-logo {
    height: 30px;
    width: 278px;
}

@tailwind base;
@tailwind components;
@tailwind utilities;